import React, { useState } from 'react';
import Reservations from "./reservations";
import DesImage from "../assets/IMG_9276.jpg";
import BrunImage from "../assets/IMG_9868.jpg";
import DinnImage from "../assets/IMG_9830.jpg";
import CotcImage from "../assets/cocteles.jpg";
import PostresImage from "../assets/postres.jpg";

function Menu() {
  const [activeTab, setActiveTab] = useState('desayunos');

  return (
    <section id="menu" className="bg-menu">
      <div className="flex flex-col items-center py-8">
        <h1 className="text-6xl mt-12 font-bold mb-8 font-calligraphy text-white text-center">Menu</h1>

        {/* Tabs for mobile view */}
        <div className="md:hidden flex flex-col items-center mb-4">
          <div className="flex space-x-4">
            <button
              className={`transition ease-in-out delay-150 bg-yellow-300 text-black px-4 py-2 rounded-md hover:bg-blue-900 hover:text-white font-calligraphy ${activeTab === 'desayunos' ? 'active' : ''}`}
              onClick={() => setActiveTab('desayunos')}
            >
              Desayunos
            </button>
            <button
              className={`transition ease-in-out delay-150 bg-yellow-300 text-black px-4 py-2 rounded-md hover:bg-blue-900 hover:text-white font-calligraphy ${activeTab === 'comida' ? 'active' : ''}`}
              onClick={() => setActiveTab('comida')}
            >
              Comida
            </button>
            <button
              className={`transition ease-in-out delay-150 bg-yellow-300 text-black px-4 py-2 rounded-md hover:bg-blue-900 hover:text-white font-calligraphy ${activeTab === 'bebidas' ? 'active' : ''}`}
              onClick={() => setActiveTab('bebidas')}
            >
              Bebidas
            </button>
            <button
              className={`transition ease-in-out delay-150 bg-yellow-300 text-black px-4 py-2 rounded-md hover:bg-blue-900 hover:text-white font-calligraphy ${activeTab === 'postres' ? 'active' : ''}`}
              onClick={() => setActiveTab('postres')}
            >
              Postres y Cocteles
            </button>
          </div>
        </div>

        {/* Content for desktop view */}
        <div className="hidden md:grid md:grid-cols-4 md:gap-8 px-14">
  <div className="bg-white">
    <img
      src={DesImage}
      alt="Desayunos"
      style={{ height: "35rem" }}
      className="w-full object-cover py-8 px-4 mb-5"
    />
    <div className="relative inset-0 flex items-center justify-center bottom-0 mb-16">
      <button
        type="submit"
        onClick={() => {
          window.open('https://storage.googleapis.com/corlosqui.appspot.com/MenuDesayuno.pdf', '_blank');
        }}
        className="transition ease-in-out delay-150 bg-yellow-300 text-black px-4 py-2 rounded-md hover:bg-blue-900 hover:text-white font-calligraphy"
      >
        Desayunos
      </button>
    </div>
  </div>

  <div className="relative bg-white">
    <img
      src={BrunImage}
      alt="Comida"
      style={{ height: "35rem" }}
      className="w-full object-cover py-8 px-4 mb-5"
    />
    <div className="relative inset-0 flex items-center justify-center bottom-0 mb-16 rounded-lg">
      <button
        type="submit"
        onClick={() => {
          window.open('https://storage.googleapis.com/corlosqui.appspot.com/ECP%20-%20NVO%20MENU%20JULIO24.pdf', '_blank');
        }}
        className="transition ease-in-out delay-150 bg-yellow-300 text-black px-4 py-2 rounded-md hover:bg-blue-900 hover:text-white font-calligraphy"
      >
        Comida
      </button>
    </div>
  </div>

  <div className="relative bg-white">
    <img
      src={DinnImage}
      alt="Bebidas"
      style={{ height: "35rem" }}
      className="w-full object-cover py-8 px-4 mb-5"
    />
    <div className="relative inset-0 flex items-center justify-center bottom-0 mb-16 rounded-lg">
      <button
        type="submit"
        onClick={() => {
          window.open('https://storage.googleapis.com/corlosqui.appspot.com/ECBP%20-%20NVO%20MENU%20JULIO24.pdf', '_blank');
        }}
        className="transition ease-in-out delay-150 bg-yellow-300 text-black px-4 py-2 rounded-md hover:bg-blue-900 hover:text-white font-calligraphy"
      >
        Bebidas
      </button>
    </div>
  </div>

  <div className="relative bg-white">
  <div className="flex flex-col p-3">
    {/* Imagen de Postre (arriba) */}
    <div className="w-full">
      <img
        src={PostresImage}
        alt="Postre"
        style={{ height: "16rem" }}
        className="w-full h-full object-cover mt-5 shadow-lg"
      />
    </div>

    {/* Imagen de Cóctel (abajo) */}
    <div className="w-full">
      <img
        src={CotcImage}
        alt="Cóctel"
        style={{ height: "15.5rem" }}
        className="w-full h-full object-cover shadow-lg"
      />
    </div>
  </div>

  <div className="relative inset-0 flex items-center justify-center bottom-0 mt-8 rounded-lg">
    <button
      type="submit"
      onClick={() => {
        window.open('https://grupocielo.my.canva.site/cocteleria-postres#postre-de-la-casa', '_blank');
      }}
      className="transition ease-in-out delay-150 bg-yellow-300 text-black px-4 py-2 rounded-md hover:bg-blue-900 hover:text-white font-calligraphy"
    >
      Postres y Cocteles
    </button>
  </div>
</div>



</div>


        {/* Content for mobile view */}
        <div className={`md:hidden ${activeTab === 'desayunos' ? 'block' : 'hidden'}`}>
          <div className="bg-white">
            <img
              src={DesImage}
              alt="Desayunos"
              style={{ height: "35rem" }}
              className="w-full object-cover py-8 px-4 mb-5"
            />
            <div className="relative inset-0 flex items-center justify-center bottom-0 mb-16">
              <button
                type="submit"
                onClick={() => {
                  window.open('https://storage.googleapis.com/corlosqui.appspot.com/Menu%20Desayunos%20%2010%20de%20Mayo%20%20(2).pdf', '_blank');
                }}
                className="mb-4 transition ease-in-out delay-150 bg-yellow-300 text-black px-4 py-2 rounded-md hover:bg-blue-900 hover:text-white font-calligraphy"
              >
                Ver Menu
              </button>
            </div>
          </div>
        </div>
        <div className={`md:hidden ${activeTab === 'comida' ? 'block' : 'hidden'}`}>
          <div className="bg-white">
            <img
              src={BrunImage}
              alt="Comida"
              style={{ height: "35rem" }}
              className="w-full object-cover py-8 px-4 mb-5"
            />
            <div className="relative inset-0 flex items-center justify-center bottom-0 mb-16 rounded-lg">
              <button
                type="submit"
                onClick={() => {
                  window.open('https://storage.googleapis.com/corlosqui.appspot.com/ECP%20-%20NVO%20MENU%20JULIO24.pdf', '_blank');
                }}
                className="mb-4 transition ease-in-out delay-150 bg-yellow-300 text-black px-4 py-2 rounded-md hover:bg-blue-900 hover:text-white font-calligraphy"
              >
                Ver Menu
              </button>
            </div>
          </div>
        </div>
        <div className={`md:hidden ${activeTab === 'bebidas' ? 'block' : 'hidden'}`}>
          <div className="bg-white">
            <img
              src={DinnImage}
              alt="Bebidas"
              style={{ height: "35rem" }}
              className="w-full object-cover py-8 px-4 mb-5"
            />
            <div className="relative inset-0 flex items-center justify-center bottom-0 mb-16 rounded-lg">
              <button
                type="submit"
                onClick={() => {
                  window.open('https://storage.googleapis.com/corlosqui.appspot.com/ECBP%20-%20NVO%20MENU%20JULIO24.pdf', '_blank');
                }}
                className="mb-4 transition ease-in-out delay-150 bg-yellow-300 text-black px-4 py-2 rounded-md hover:bg-blue-900 hover:text-white font-calligraphy"
              >
                Ver Menu
              </button>
            </div>
          </div>
        </div>
        

        {/* New Section for Postres y Cocteles */}
        <div className={`md:hidden ${activeTab === 'postres' ? 'block' : 'hidden'}`}>
          <div className="bg-gradient-to-r from-yellow-400 via-red-400 to-pink-400 text-center py-20 px-4 rounded-lg shadow-lg">
            <h2 className="text-4xl font-bold text-white font-calligraphy mb-4">Postres y Cocteles</h2>
            <p className="text-xl text-white font-calligraphy mb-4">Descubre nuestra deliciosa selección de postres y cocteles. ¡Satisfacción garantizada!</p>
            <img
              src={DinnImage}
              alt="Bebidas"
              style={{ height: "35rem" }}
              className="w-full object-cover py-8 px-4 mb-5"
            />
            <div className="flex justify-center">
              <button
                type="submit"
                onClick={() => alert('¡Pronto disponible!')}
                className="transition ease-in-out delay-150 bg-yellow-300 text-black px-6 py-3 rounded-md hover:bg-blue-900 hover:text-white font-calligraphy"
              >
                Ver Menu
              </button>
            </div>
          </div>
        </div>
      </div>
      <Reservations />
    </section>
  );
}

export default Menu;